import React from 'react'
import './footer.css'

const Footer = () => {
  return (
	<div>Footer</div>
  )
}

export default Footer
